import React from "react";
import Select from "react-flags-select";

import { NavLink } from "react-router-dom";

import { Link } from "src/components/shared";
import { useNavBar } from "./nav-bar-hooks";

import "./nav-bar.scss";

import Helmets from "src/assets/images/helmets.png";

export const NavBarComponent = React.memo(() => {
  const { getRouteProps, getSelectProps } = useNavBar();
  return (
    <div className="nav-bar">
      <NavLink className="nav-bar__main" to="/">
        <img src={Helmets} />
      </NavLink>
      <nav className="nav-bar__routes">
        <Link {...getRouteProps("/racer")} path="nav.racer" />
        <Link {...getRouteProps("/table")} path="nav.table" />
        <Link {...getRouteProps("/rating")} path="nav.rating" />
        <Link {...getRouteProps("/regulation")} path="nav.regulation" />
        <Link {...getRouteProps("/contact")} path="nav.contact" />
      </nav>
      <Select {...getSelectProps()} />
    </div>
  );
});

NavBarComponent.displayName = "NavBarComponent";
