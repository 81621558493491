import axios from "axios";

import { Game, Racer, Tournament } from "src/types";
import { Response } from "./services-types";

const uri = `${window.location.protocol === "https:" ? "https://" : "http://"}${
  window.location.host
}`;

const instance = axios.create({
  baseURL: uri,
});

export const API = {
  game: (id: number) =>
    instance<Response<Game>>({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "game.info",
        params: id,
      },
      url: "api",
    }),
  games: (id: number) =>
    instance<Response<Array<Game>>>({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "tournament.games",
        params: id,
      },
      url: "api",
    }),
  login: () =>
    instance({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "user.login",
        params: {
          username: "admin",
          password: "admin",
        },
      },
      url: "auth",
    }),
  logout: () =>
    instance({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "user.logout",
      },
      url: "auth",
    }),
  racers: () =>
    instance<Response<Array<Racer>>>({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "racer.list",
      },
      url: "api",
    }),
  tournament: (id: number) =>
    instance<Response<Tournament>>({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "tournament.info",
        params: id,
      },
      url: "api",
    }),
  tournaments: (from: string) =>
    instance<Response<Array<Tournament>>>({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "tournament.list",
        params: from,
      },
      url: "api",
    }),
  whoami: () =>
    instance({
      method: "POST",
      data: {
        jsonrpc: "2.0",
        id: (~~(Math.random() * 1e8)).toString(16),
        method: "user.whoami",
      },
      url: "admin",
    }),
};
