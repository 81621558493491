import React from "react";

import { Text } from "src/components/shared";
import { useRacers } from "./rating-hooks";

import "./rating.scss";

export const RatingComponent = React.memo(() => {
  const { getDataProps, getNameProps, values } = useRacers();
  return (
    <div className="rating">
      <div className="rating__header">
        <Text
          className="rating__title rating__title--primary"
          path="rating.championship"
        />
        <Text
          className="rating__title rating__title--secondary"
          path="rating.information"
        />
      </div>
      <div className="rating__body">
        {values.racers.map((racer, index) => (
          <div
            className="rating__racer"
            key={racer.place}
            {...getDataProps(index + 1)}
          >
            <span className="rating__info">{index + 1}</span>
            <Text {...getNameProps(racer)} />
            <span className="rating__info">{racer.rating}</span>
          </div>
        ))}
      </div>
    </div>
  );
});

RatingComponent.displayName = "RatingComponent";
