import React from "react";
import cn from "classnames";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const useNavBar = () => {
  const location = useLocation();

  const { i18n } = useTranslation();

  const getRouteProps = React.useCallback(
    (to: string) => ({
      className: cn("nav-bar__route", {
        "nav-bar__route--active": location.pathname === to,
      }),
      to,
    }),
    [location.pathname]
  );

  const getSelectProps = React.useCallback(
    () => ({
      className: "nav-bar__select",
      countries: ["AM", "RU", "GB"],
      customLabels: { AM: "AM", RU: "RU", GB: "EN" },
      onSelect: (code: string) => i18n.changeLanguage(code),
      selected: i18n.language,
    }),
    [i18n]
  );

  return { getRouteProps, getSelectProps };
};
