import { Game, Order, Status } from "src/types";

export const getGames = (games: Array<Game>): Array<Game & Order & Status> => {
  return [...games]
    .sort((a, b) => a.id - b.id)
    .map((game, index, array) => {
      const result: Game & Order & Status = {
        ...game,
        order: index + 1,
        status: "started",
      };

      if (game.finished_at) {
        result.status = "finished";
      }
      if (game.canceled) {
        result.status = "canceled";
      }

      if (index === array.length - 1) {
        if (!array[index - 1].finished_at && !game.finished_at) {
          result.status = "pending";
        }
      }

      return result;
    });
};
