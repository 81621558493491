import { Racer } from "./types";
import { RACERS } from "./constants";

export const getNickname = (racer: Racer, language: string) => {
  switch (language) {
    case "RU":
      return racer.nickname;
    case "GB":
      return racer.name_en;
    case "AM":
      return racer.name_am;
    default:
      return "";
  }
};

export const getRacers = (racers: Array<Racer> = []) => {
  const sorted = [...racers].sort((a, b) => b.rating - a.rating);
  const mapped = [...sorted].map((racer) => ({
    ...racer,
    age: RACERS[racer.id].age,
    name_am: racer.name_am || RACERS[racer.id].name_am,
    name_en: racer.name_en || RACERS[racer.id].name_en,
  }));
  return mapped;
};
