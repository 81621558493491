import React from "react";

import { RacerOwnProps } from "./racer-types";
import { Text } from "src/components/shared";
import { useRacer } from "./racer-hooks";

import "./racer.scss";

export const RacerComponent = (props: RacerOwnProps) => {
  const { getNicknameProps, getRacerProps } = useRacer(props);
  return (
    <div {...getRacerProps()}>
      <span>{String(props.position).padStart(2, "0")}</span>
      <Text {...getNicknameProps()} />
      <span>{props.racer.rating}</span>
    </div>
  );
};
