import i18n from "i18next";

import { initReactI18next } from "react-i18next";

const RESOURCES = {
  AM: {
    translation: {
      contacts: {
        header: {
          primary: "ԴԻՄԵԼ ԱՌԱՋՆՈՒԹՅԱՆԸ ՄԱՍՆԱԿՑԵԼՈՒ ՀԱՄԱՐ",
          secondary: "ՄԱՍՆԱԿՑՈՒԹՅԱՆ ՀԱՅՏ",
        },
        business: {
          title: "ՁԵՌՆԱՐԿՈՒԹՅԱՆ ԱՆՎԱՆՈՒՄԸ",
          value: "”ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԱՎՏՈՄՈԴԵԼԱՅԻՆ ՍՊՈՐՏԻ ՖԵԴԵՐԱՑԻԱ”ՀԿ",
        },
        adress: {
          title: "ՀԱՍՑԵ",
          value: "ՀԱՅԱՍՏԱՆ ԵՐԵՒԱՆ ՆՈՐՔ-ՄԱՐԱՇ ՆՈՐՔԻ 11-ՐԴ ՓՈՂՈՑ Շ. 49",
        },
        director: {
          title: "ՏՆՕՐԵՆ",
          value: "ԱՎԱՆԵՍՅԱՆ ԱՐՍԵՆ ԷԴՈՒԱՐԴԻ",
        },
        inn: "ՀՎՀՀ",
        registration: "ԳՐԱՆՑՄԱՆ ՀԱՄԱՐԸ",
        name: "ԱՆՈՒՆ",
        surname: "ԱԶԳԱՆՈՒՆ",
        tel: "ԿՈՆՏԱԿՏԱՅԻՆ ՀԱՄԱՐ",
        mail: "ԷԼԵԿՏՐՈՆԱՅԻՆ ՀԱՍՑԵ",
        comment: "ՄԵԿՆԱԲԱՆՈՒԹՅՈՒՆ",
        send: "ՈՒՂԱՐԿԵԼ",
        errors: {
          empty: "Անհրաժեշտ է լրացնել դաշտը",
          words: "Դաշտը պետք է պարունակի միայն տառեր",
          mail: "Պարունակում է միայն լատինատառ և հատուկ նիշեր ”@” և ”.”",
        },
      },
      nav: {
        racer: "ՄՐՑԱՐՇԱՎՈՐԴՆԵՐ",
        table: "ԺԱՄԱՆԱԿԱՑՈՒՅՑ",
        rating: "ՎԱՐԿԱՆԻՇ",
        regulation: "ԿԱՆՈՆԱԿԱՐԳԵՐ",
        contact: "ԿՈՆՏԱԿՏՆԵՐ",
      },
      racers: {
        age: "ՏԱՐԻՔ",
        rating: "ԸՆԹԱՑԻԿ ՎԱՐԿԱՆԻՇ",
        years: "ՏԱՐԻՆԵՐ/ՏԱՐԻ",
      },
      rating: {
        championship: "ԸՆԹԱՑԻԿ ԱՌԱՋՆՈՒԹՅՈՒՆ",
        information: "ՎԱՐԿԱՆԻՇԱՅԻՆ ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆ",
      },
      regulations: {
        plan: "ՕՐԱՑՈՒՑԱՅԻՆ ՊԼԱՆ (RU)",
        plan2024: "ՕՐԱՑՈՒՑԱՅԻՆ ՊԼԱՆ 2024 (RU)",
        competition: "ՄՐՑՈՒՄՆԵՐԻ ԱՆՑԿԱՑՄԱՆ ԿԱՆՈՆԱԿԱՐԳ (RU)",
        competition2024: "ՄՐՑՈՒՄՆԵՐԻ ԱՆՑԿԱՑՄԱՆ ԿԱՆՈՆԱԿԱՐԳ 2024 (RU)",
        statute: "ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ԿԱՆՈՆԱԴՐՈՒԹՅՈՒՆ (ARM)",
        statuteRU: "ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ԿԱՆՈՆԱԴՐՈՒԹՅՈՒՆ (RU)",
      },
      table: {
        start: "ՄԵԿՆԱՐԿԵԼ Է",
        tournament: "ՄՐՑԱՇԱՐ",
        race: "ՄՐՑԱՎԱԶՔ",
        completed: "ԱՎԱՐՏՎԱԾ",
        canceled: "ՉԵՂԱՐԿՎԱԾ",
        progress: "ԸՆԹԱՑՔԻ ՄԵՋ Է",
        idle: "ՍՊԱՍՄԱՆ ՄԵՋ Է",
        line: "ԵՐԹՈՒՂԻ",
        finish: "ԱՎԱՐՏԵԼ",
      },
    },
  },
  GB: {
    translation: {
      contacts: {
        header: {
          primary: "APPLY TO PARTICIPATE IN THE CHAMPIONSHIP",
          secondary: "APPLICATION FOR PARTICIPATION",
        },
        business: {
          title: "BUSINESS NAME",
          value: "OO ”AUTOMOBILE SPORTS OF THE REPUBLIC OF ARMENIA”",
        },
        adress: {
          title: "ADDRESS",
          value: "ARMENIA YEREVAN NORK-MARASH 11TH ST. MINK D.49",
        },
        director: {
          title: "DIRECTOR",
          value: "AVANESYAN ARSEN EDUARDOVICH",
        },
        inn: "TIN",
        registration: "REGISTRATION NUMBER",
        name: "NAME",
        surname: "SURNAME",
        tel: "CONTACT NUMBER",
        mail: "E-MAIL",
        comment: "COMMENT",
        send: "SEND",
        errors: {
          empty: "It is necessary to fill out the field",
          words: "The field should contain only letters",
          mail: "Contains only the Latin and special symbols “@” and “.”",
        },
      },
      nav: {
        racer: "RACERS",
        table: "SCHEDULE",
        rating: "RATING",
        regulation: "REGULATIONS",
        contact: "CONTACTS",
      },
      racers: {
        age: "AGE",
        rating: "CURRENT RATING",
        years: "YEARS/YEAR",
      },
      rating: {
        championship: "THE CURRENT CHAMPIONSHIP",
        information: "RATING INFORMATION",
      },
      regulations: {
        plan: "CALENDAR PLAN (RU)",
        plan2024: "CALENDAR PLAN 2024 (RU)",
        competition: "COMPETITION REGULATION (RU)",
        competition2024: "COMPETITION REGULATION 2024 (RU)",
        statute: "ORGANIZATION CHARTER (ARM)",
        statuteRU: "ORGANIZATION CHARTER (RU)",
      },
      table: {
        start: "RUNNED",
        tournament: "TOURNAMENT",
        race: "RACE",
        completed: "COMPLETED",
        canceled: "CANCELED",
        progress: "IN PROGRESS",
        idle: "IN WAITING",
        line: "LINE",
        finish: "FINISH",
      },
    },
  },
  RU: {
    translation: {
      contacts: {
        header: {
          primary: "ПОДАЙТЕ ЗАЯВКУ ДЛЯ УЧАСТИЯ В ЧЕМПИОНАТЕ",
          secondary: "ЗАЯВКА НА УЧАСТИЕ",
        },
        business: {
          title: "НАИМЕНОВАНИЕ ПРЕДПРИЯТИЯ",
          value: "ОО ”ФЕДЕРАЦИЯ АВТОМОДЕЛЬНОГО СПОРТА РЕСПУБЛИКИ АРМЕНИЯ”",
        },
        adress: {
          title: "АДРЕС",
          value: "АРМЕНИЯ ЕРЕВАН НОРК-МАРАШ 11-Я УЛ. НОРКА Д.49",
        },
        director: {
          title: "ДИРЕКТОР",
          value: "АВАНЕСЯН АРСЕН ЭДУАРДОВИЧ",
        },
        inn: "ИНН",
        registration: "РЕГИСТРАЦИОННЫЙ НОМЕР",
        name: "ИМЯ",
        surname: "ФАМИЛИЯ",
        tel: "КОНТАКТНЫЙ НОМЕР",
        mail: "E-MAIL",
        comment: "КОММЕНТАРИЙ",
        send: "ОТПРАВИТЬ",
        errors: {
          empty: "Необходимо заполнить поле",
          words: "Поле должно содержать только буквы",
          mail: "Содержит только латиницу и специальные символы “@” и “.”",
        },
      },
      nav: {
        racer: "ГОНЩИКИ",
        table: "РАСПИСАНИЕ",
        rating: "РЕЙТИНГ",
        regulation: "РЕГЛАМЕНТЫ",
        contact: "КОНТАКТЫ",
      },
      racers: {
        age: "ВОЗРАСТ",
        rating: "ТЕКУЩИЙ РЕЙТИНГ",
        years: "ЛЕТ/ГОДА",
      },
      rating: {
        championship: "ТЕКУЩИЙ ЧЕМПИОНАТ",
        information: "РЕЙТИНГОВАЯ ИНФОРМАЦИЯ",
      },
      regulations: {
        plan: "КАЛЕНДАРНЫЙ ПЛАН (RU)",
        plan2024: "КАЛЕНДАРНЫЙ ПЛАН 2024 (RU)",
        competition: "РЕГЛМЕНТ ПРОВЕДЕНИЯ СОРЕВНОВАНИЙ (RU)",
        competition2024: "РЕГЛМЕНТ ПРОВЕДЕНИЯ СОРЕВНОВАНИЙ 2024 (RU)",
        statute: "УСТАВ ОРГАНИЗАЦИИ (ARM)",
        statuteRU: "УСТАВ ОРГАНИЗАЦИИ (RU)",
      },
      table: {
        start: "ЗАПУЩЕН",
        tournament: "ТУРНИР",
        race: "ГОНКА",
        completed: "ЗАВЕРШЕНА",
        canceled: "ОТМЕНЕНА",
        progress: "В ПРОЦЕССЕ",
        idle: "В ОЖИДАНИИ",
        line: "ЛИНИЯ",
        finish: "ФИНИШ",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources: RESOURCES,
  fallbackLng: "AM",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
