import React from "react";
import cn from "classnames";
import ru from "date-fns/locale/ru";

import { addMonths, setDate } from "date-fns";
import { ReactDatePickerProps, registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import { API } from "src/services";
import { formatDate } from "./table-utils";
import { Mode } from "./table-types";
import { Racer, Tournament } from "src/types";

registerLocale("ru", ru);

export const useTable = () => {
  const [offset, setOffset] = React.useState<number>(0);
  const [from, setFrom] = React.useState<Date | null>(null);
  const [mode, setMode] = React.useState<Mode>("tournament");
  const [tournament, setTournament] = React.useState<Tournament | null>(null);
  const [tournaments, setTournaments] = React.useState<Array<Tournament>>([]);
  const [racers, setRacers] = React.useState<Array<Racer>>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (tournament) {
      API.tournament(tournament.id).then((response) => {
        setRacers(response.data.result.racers);
      });
    }
  }, [tournament]);

  React.useEffect(() => {
    const date = formatDate(addMonths(setDate(new Date(), 1), -1));
    API.tournaments(date).then((response) => {
      if (response.data.result) {
        const length = response.data.result.length;
        setTournaments(response.data.result);
        setOffset(length > 5 ? length - 5 : 0);
        setTournament(response.data.result[length - 1]);
      }
    });
  }, []);

  React.useEffect(() => {
    if (from) {
      const dates = tournaments.map((tournament) => new Date(tournament.created_at));
      for (let i = 0; i < dates.length; i++) {
        if (formatDate(dates[i]) === formatDate(from)) {
          if (tournaments.length > 5) {
            setOffset(i > tournaments.length - 5 ? tournaments.length - 5 : i);
          }

          let tournament = tournaments[i];

          for (let j = i; j < dates.length; j++) {
            if (formatDate(dates[j]) === formatDate(from)) {
              tournament = tournaments[j];
            }
          }

          setTournament(tournament);
          break;
        }
      }
    }
  }, [from, tournaments]);

  const getPrevProps = React.useCallback(
    () => ({
      disabled: tournaments.length < 5,
      className: "table__button table__button__prev",
      onClick: () => setOffset((value) => (value > 0 ? value - 1 : 0)),
    }),
    [tournaments]
  );

  const getNextProps = React.useCallback(
    () => ({
      className: "table__button table__button__next",
      disabled: tournaments.length < 5,
      onClick: () => {
        setOffset((value) =>
          value < tournaments.length - 5 ? value + 1 : tournaments.length - 5
        );
      },
    }),
    [tournaments]
  );

  const getTournamentProps = React.useCallback(
    (value: Tournament) => ({
      className: cn("table__button", "table__button__tournament", {
        "table__button__tournament--selected": tournament
          ? tournament.id === value.id
          : false,
      }),
      onClick: () => {
        setTournament(value);
        setMode("tournament");
      },
      selected: value.id === tournament?.id,
    }),
    [tournament]
  );

  const getCalendarButtonProps = React.useCallback(
    () => ({
      className: cn("table__button", "table__button__calendar", {
        "table__button__calendar--active": mode === "calendar",
      }),
      onClick: () =>
        setMode((value) => (value === "calendar" ? "tournament" : "calendar")),
    }),
    [mode]
  );

  const getCalendarProps = React.useCallback(
    (month: number) =>
      ({
        highlightDates: tournaments.map(
          (tournament) => new Date(tournament.created_at)
        ),
        inline: true,
        locale: "ru",
        forceShowMonthNavigation: false,
        onChange: (date) => {
          if (date) {
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            setFrom(date);
            setMode("tournament");
          }
        },
        selected: addMonths(new Date(), 0 - month),
      } as ReactDatePickerProps),
    [tournaments]
  );

  const getDateProps = React.useCallback(
    (value: string) => ({
      className: "table__title table__title--date",
      style: {
        fontSize: value.length > 22 ? "1rem" : "1.286rem",
      } as React.CSSProperties,
      value,
    }),
    []
  );

  const getTitleProps = React.useCallback(
    (value: string) => ({
      className: "table__title",
      style: {
        fontSize: value.length > 13 ? "2rem" : "2.286rem",
      } as React.CSSProperties,
      value,
    }),
    []
  );

  const texts = React.useMemo(
    () => ({
      start: t("table.start"),
      tournament: t("table.tournament"),
    }),
    [t]
  );

  const values = React.useMemo(
    () => ({
      mode,
      texts,
      racers,
      tournament,
      tournaments: tournaments.slice(offset, offset + 5),
    }),
    [mode, offset, texts, racers, tournament, tournaments]
  );

  return {
    getCalendarProps,
    getCalendarButtonProps,
    getDateProps,
    getNextProps,
    getPrevProps,
    getTitleProps,
    getTournamentProps,
    values,
  };
};
