import React from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";

import { RacerOwnProps } from "./racer-types";
import { getNickname } from "src/utils";

export const useRacer = (props: RacerOwnProps) => {
  const { i18n } = useTranslation();

  const getRacerProps = React.useCallback(
    () => ({
      className: cn("racer", {
        "racer--first": props.position === 1,
        "racer--second": props.position === 2,
        "racer--third": props.position === 3,
      }),
    }),
    [props.position]
  );

  const getNicknameProps = React.useCallback(
    () => ({
      value: getNickname(props.racer, i18n.language),
    }),
    [props.racer, i18n.language]
  );

  return { getNicknameProps, getRacerProps };
};
