import React from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";

import { GameOwnProps, Position } from "./game-types";
import { getNickname } from "src/utils";

export const useGame = (props: GameOwnProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const { i18n, t } = useTranslation();

  const getInfoProps = React.useCallback(
    () => ({
      className: cn("game__info", {
        "game__info--cancelled": props.game.status === "canceled",
        "game__info--started": props.game.status === "started",
        "game__info--pending": props.game.status === "pending",
      }),
      onClick: () => setOpen(true),
    }),
    [props.game]
  );

  const getCloseProps = React.useCallback(
    () => ({
      className: "game__result__close",
      onClick: () => setOpen(false),
    }),
    []
  );

  const getModalProps = React.useCallback(
    () => ({
      onClose: () => setOpen(false),
      open,
    }),
    [open]
  );

  const getSlideProps = React.useCallback(
    () => ({
      in: open,
    }),
    [open]
  );

  const getPositionProps = React.useCallback(
    (position: Position) => ({
      className: cn("game__result__position", {
        "game__result__position--first": position.finish === 1,
        "game__result__position--second": position.finish === 2,
        "game__result__position--third": position.finish === 3,
      }),
    }),
    []
  );

  const status = React.useMemo(() => {
    switch (props.game.status) {
      case "canceled":
        return t("table.canceled");
      case "finished":
        return t("table.completed");
      case "pending":
        return t("table.idle");
      case "started":
        return t("table.progress");
      default:
        return "";
    }
  }, [props.game, t]);

  const positions = React.useMemo(
    () =>
      props.game.racer_pos.map((start, index) => {
        const racer = props.racers.find((racer) => racer.id === start);
        const nickname = racer ? getNickname(racer, i18n.language) : "НЕТ ДАННЫХ";
        const line = index + 1;
        const finish = props.game.line_pos.indexOf(line) + 1;
        return { finish, line, nickname } as Position;
      }),
    [i18n.language, props.game, props.racers]
  );

  const texts = React.useMemo(
    () => ({
      line: t("table.line"),
      finish: t("table.finish"),
      race: t("table.race"),
    }),
    [t]
  );

  const values = React.useMemo(
    () => ({
      positions,
      status,
      texts,
    }),
    [positions, status, texts]
  );

  return {
    getCloseProps,
    getInfoProps,
    getModalProps,
    getPositionProps,
    getSlideProps,
    values,
  };
};
