import { Racer } from "./types";

export const RACERS: Record<number, Racer> = {
  1: {
    id: 1,
    active: true,
    rating: 0,
    skill: 0,
    age: 27,
    nickname: "Артём Костоваров",
    name_am: "Կոստովարով Արտյոմ",
    name_en: "Artyom Kostovarov",
  },
  2: {
    id: 2,
    active: true,
    rating: 0,
    skill: 0,
    age: 29,
    nickname: "Александр Завьялов  ",
    name_am: "Ալեքսանդր Զավյալով",
    name_en: "Alexandr Zavyalov",
  },
  3: {
    id: 3,
    active: true,
    rating: 0,
    skill: 0,
    age: 27,
    nickname: "Лазарь Медведев ",
    name_am: "Լազար Մեդվեդեվ ",
    name_en: "Lazar Medvedev ",
  },
  4: {
    id: 4,
    active: true,
    rating: 0,
    skill: 0,
    age: 32,
    nickname: "Олександр Фаворов",
    name_am: "Ֆավորով Օլեքսանդր",
    name_en: "Oleksandr Favorov ",
  },
  5: {
    id: 5,
    active: true,
    rating: 0,
    skill: 0,
    age: 23,
    nickname: "Вячеслав Минасов ",
    name_am: "Վյաչեսլավ Մինասով ",
    name_en: "Vyacheslav Minasov ",
  },
  6: {
    id: 6,
    active: true,
    rating: 0,
    skill: 0,
    age: 24,
    nickname: "Виктор Сафарян",
    name_am: "Վիկտոր Սաֆարով",
    name_en: "Victor Safaryan",
  },
  7: {
    id: 7,
    active: true,
    rating: 0,
    skill: 0,
    age: 24,
    nickname: "Арсен Погосян",
    name_am: "Պողոսյան Արսեն",
    name_en: "Arsen Poghosyan",
  },
  8: {
    id: 8,
    active: true,
    rating: 0,
    skill: 0,
    age: 28,
    nickname: "Анастасия Ахметзянова",
    name_am: "Ախմետզյանովա",
    name_en: "Anastasiya Axmetzianova",
  },
  9: {
    id: 9,
    active: true,
    rating: 0,
    skill: 0,
    age: 25,
    nickname: "Петр Киреев",
    name_am: "Պյոտր Կիրեև",
    name_en: "Petr Kireev",
  },
  10: {
    id: 10,
    active: true,
    rating: 0,
    skill: 0,
    age: 22,
    nickname: "Артём Валюк  ",
    name_am: "Վալյուկ Արտյոմ",
    name_en: "Artyom Valyuk",
  },
  11: {
    id: 11,
    active: true,
    rating: 0,
    skill: 0,
    age: 32,
    nickname: "Петр Травников",
    name_am: "Պյոտր Տրավնիկով",
    name_en: "Petr Travnikov",
  },
  12: {
    id: 12,
    active: true,
    rating: 0,
    skill: 0,
    age: 29,
    nickname: "Иван Степанов ",
    name_am: "Ստեպանով Իվան",
    name_en: "Ivan Stepanov",
  },
  13: {
    id: 13,
    active: true,
    rating: 0,
    skill: 0,
    age: 33,
    nickname: "Алишер Умаров ",
    name_am: "Ումարով Ալիշեր",
    name_en: "Alisher Umarov",
  },
  14: {
    id: 14,
    active: true,
    rating: 0,
    skill: 0,
    age: 32,
    nickname: "Илья Шендригайлов ",
    name_am: "Իլյա Շենդրիգայլով",
    name_en: "Ilya Shendrigaylov",
  },
  15: {
    id: 15,
    active: true,
    rating: 0,
    skill: 0,
    age: 26,
    nickname: "Сергей Кайдашов ",
    name_am: "Սերգեյ Կայդաշով",
    name_en: "Sergey Kaydashev",
  },
  16: {
    id: 16,
    active: true,
    rating: 0,
    skill: 0,
    age: 33,
    nickname: "Макар Шопот  ",
    name_am: "Շոպոտ Մակար",
    name_en: "Makar Shopot",
  },
  17: {
    id: 17,
    active: true,
    rating: 0,
    skill: 0,
    age: 24,
    nickname: "Арсений Попович",
    name_am: "Պոպովիչ Արսենիյ",
    name_en: "Arseniy Popovich ",
  },
  18: {
    id: 18,
    active: true,
    rating: 0,
    skill: 0,
    age: 35,
    nickname: "Константин Зеленов ",
    name_am: "Կոնստանտին Զելենով ",
    name_en: "Konstantin Zelenov ",
  },
  19: {
    id: 19,
    active: true,
    rating: 0,
    skill: 0,
    age: 18,
    nickname: "Лилит Сукиасян ",
    name_am: "Սուքիասյան Լիլիթ ",
    name_en: "Lilit Sukiasyan ",
  },
  20: {
    id: 20,
    active: true,
    rating: 0,
    skill: 0,
    age: 28,
    nickname: "Сона Даниелян",
    name_am: "Դանիելյան Սոնա",
    name_en: "Sona Danielyan",
  },
  21: {
    id: 21,
    active: true,
    rating: 0,
    skill: 0,
    age: 32,
    nickname: "Арпине Арутюнян ",
    name_am: "Հարությունյան Արփինե",
    name_en: "Arpine Harutyunyan ",
  },
  22: {
    id: 22,
    active: true,
    rating: 0,
    skill: 0,
    age: 24,
    nickname: "Андрей Рузанов ",
    name_am: "Ռուզանով Անդրեյ ",
    name_en: "Andrey Ruzanov",
  },
  23: {
    id: 23,
    active: true,
    rating: 0,
    skill: 0,
    age: 18,
    nickname: "Анжела Арменакян ",
    name_am: "Արմենակյան Անժելա ",
    name_en: "Anzhela Armenakyan",
  },
  24: {
    id: 24,
    active: true,
    rating: 0,
    skill: 0,
    age: 23,
    nickname: "Арман Бабаян ",
    name_am: "Բաբայան Արման",
    name_en: "Arman Babayan",
  },
  25: {
    id: 25,
    active: true,
    rating: 0,
    skill: 0,
    age: 20,
    nickname: "Мелина Гигоян ",
    name_am: "Գիգոյան Մելինա ",
    name_en: "Melina Gigoyan",
  },
  26: {
    id: 26,
    active: true,
    rating: 0,
    skill: 0,
    age: 23,
    nickname: "Джон Торосян",
    name_am: "Թորոսյան Ջոն",
    name_en: "John Torosyan",
  },
  27: {
    id: 27,
    active: true,
    rating: 0,
    skill: 0,
    age: 24,
    nickname: "Лида Петросян ",
    name_am: "Պետրսյան Լիդա",
    name_en: "Lida Petrosyan",
  },
  28: {
    id: 28,
    active: true,
    rating: 0,
    skill: 0,
    age: 32,
    nickname: "Режех Мохаммадреза ",
    name_am: "Ռեժեխ Մոհամմադրեզա",
    name_en: "Rezheh Mohammadreza ",
  },
  29: {
    id: 29,
    active: true,
    rating: 0,
    skill: 0,
    age: 22,
    nickname: "Седа Джаноян",
    name_am: "Ջանոյան Սեդա",
    name_en: "Janoyan Seda",
  },
  30: {
    id: 30,
    active: true,
    rating: 0,
    skill: 0,
    age: 22,
    nickname: "Элен Хачатрян ",
    name_am: "Խաչատրայն Էլեն ",
    name_en: "Khachatryan Elen",
  },
};
