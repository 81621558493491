import React from "react";
import MuiModal from "@mui/material/Modal";
import MuiSlide from "@mui/material/Slide";

import { GameOwnProps } from "./game-types";
import { Text } from "src/components/shared";
import { useGame } from "./game-hooks";

import "./game.scss";

import Close from "src/assets/images/close.png";

export const GameComponent = (props: GameOwnProps) => {
  const {
    getCloseProps,
    getInfoProps,
    getModalProps,
    getPositionProps,
    getSlideProps,
    values,
  } = useGame(props);
  return (
    <div className="game">
      <div {...getInfoProps()}>
        <span>{props.game.order}.</span>
        <Text value={`${values.texts.race} ${props.game.id}`} />
        <Text value={values.status} />
      </div>
      <MuiModal {...getModalProps()}>
        <MuiSlide direction="up" {...getSlideProps()}>
          <div className="game__result">
            <button {...getCloseProps()}>
              <img src={Close} height="30" width="30" />
            </button>
            <div className="game__result__content">
              <div className="game__result__header">
                <span>{props.game.id}</span>
                <Text value={values.texts.line} />
                <Text value={values.texts.finish} />
              </div>
              {values.positions.map((position) => (
                <div key={position.line} {...getPositionProps(position)}>
                  <Text value={position.nickname} />
                  <span>{position.line}</span>
                  <span>{position.finish}</span>
                </div>
              ))}
            </div>
          </div>
        </MuiSlide>
      </MuiModal>
    </div>
  );
};
