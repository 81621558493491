import React from "react";

import { animated } from "react-spring";

import { useRacers } from "./racers-hooks";
import { Text } from "src/components/shared";

import "./racers.scss";

export const RacersComponent = React.memo(() => {
  const { getItemProps, getNameProps, getSurnameProps, values } = useRacers();
  return (
    <div className="racers">
      <div className="racers__list">
        {values.racers.map((racer) => (
          <Text key={racer.id} {...getItemProps(racer)} />
        ))}
      </div>
      {values.racer(
        (props, racer) =>
          racer && (
            <animated.div className="racers__racer" style={props}>
              <img
                className="racers__avatar"
                src={`${process.env.PUBLIC_URL}/racers/racer-${racer.id}.png`}
              />
              <span className="racers__position">
                {String(racer.id).padStart(2, "0")}
              </span>
              <div className="racers__nickname">
                <Text {...getNameProps()} />
                <Text {...getSurnameProps()} />
              </div>
              <div className="racers__data">
                <div>
                  <Text value={values.texts.age} />
                  <Text value={`${racer.age}`} />
                </div>
                <div>
                  <Text value={values.texts.rating} />
                  <span>{racer.rating}</span>
                </div>
              </div>
            </animated.div>
          )
      )}
    </div>
  );
});

RacersComponent.displayName = "RacersComponent";
