import React from "react";

import { useTranslation } from "react-i18next";

import { LinkOwnProps } from "./link-types";

export const useLink = (props: LinkOwnProps) => {
  const { i18n, t } = useTranslation();

  const getLinkProps = React.useCallback(
    () => ({
      className: props.className,
      style: {
        fontFamily: i18n.language === "AM" && "Arm Hmk\\'s Bebas Neue",
        fontWeight: i18n.language === "AM" && 100,
      } as React.CSSProperties,
      to: props.to,
    }),
    [i18n.language, props.className, props.to]
  );

  const values = React.useMemo(
    () => ({
      translation: t(props.path),
    }),
    [t, props]
  );

  return { getLinkProps, values };
};
