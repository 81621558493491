import React from "react";

import { ButtonProps, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Errors } from "./form-types";

const REGEXP_MAIL = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const REGEXP_DECIMALS = new RegExp(/^\d+$/);
const REGEXP_WORDS = new RegExp(/^[A-Za-zА-Яа-я]+$/);

export const useForm = () => {
  const [name, setName] = React.useState<string>("");
  const [lastname, setLastname] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [mail, setMail] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Errors>({
    comment: "",
    lastname: "",
    mail: "",
    name: "",
    phone: "",
  });

  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    setErrors({
      comment: "",
      lastname: "",
      mail: "",
      name: "",
      phone: "",
    });
  }, [i18n.language]);

  const getFieldNameProps = React.useCallback(
    () =>
      ({
        className: "form__field",
        error: !!errors.name,
        fullWidth: true,
        inputProps: { maxLength: 100 },
        onChange: (event) => setName(event.target.value),
        onFocus: () => setErrors({ ...errors, name: "" }),
        value: name,
        variant: "standard",
      } as TextFieldProps),
    [errors, name]
  );

  const getFieldLastnameProps = React.useCallback(
    () =>
      ({
        className: "form__field",
        error: !!errors.lastname,
        fullWidth: true,
        inputProps: { maxLength: 100 },
        onChange: (event) => setLastname(event.target.value),
        onFocus: () => setErrors({ ...errors, lastname: "" }),
        value: lastname,
        variant: "standard",
      } as TextFieldProps),
    [errors, lastname]
  );

  const getPhoneProps = React.useCallback(
    () =>
      ({
        className: "form__field",
        error: !!errors.phone,
        fullWidth: true,
        inputProps: { maxLength: 100 },
        onChange: (event) => {
          const value = event.target.value.replaceAll("+", "");
          if (REGEXP_DECIMALS.test(value) || value.length === 0) {
            setPhone(value);
          }
        },
        onFocus: () => setErrors({ ...errors, phone: "" }),
        type: "tel",
        value: `+${phone}`,
        variant: "standard",
      } as TextFieldProps),
    [errors, phone]
  );

  const getFieldMailProps = React.useCallback(
    () =>
      ({
        className: "form__field",
        error: !!errors.mail,
        fullWidth: true,
        inputProps: { maxLength: 100 },
        onChange: (event) => setMail(event.target.value),
        onFocus: () => setErrors({ ...errors, mail: "" }),
        type: "email",
        value: mail,
        variant: "standard",
      } as TextFieldProps),
    [errors, mail]
  );

  const getFieldCommentProps = React.useCallback(
    () =>
      ({
        className: "form__field",
        error: !!errors.comment,
        fullWidth: true,
        inputProps: { maxLength: 1000 },
        onChange: (event) => setComment(event.target.value),
        onFocus: () => setErrors({ ...errors, comment: "" }),
        value: comment,
        variant: "standard",
      } as TextFieldProps),
    [errors, comment]
  );

  const onButtonClick = React.useCallback(() => {
    const validation: Errors = {
      comment: "",
      lastname: "",
      mail: "",
      name: "",
      phone: "",
    };

    if (!comment.length) {
      validation.comment = t("contacts.errors.empty");
    }
    if (!REGEXP_WORDS.test(lastname) && lastname) {
      validation.lastname = t("contacts.errors.words");
    }
    if (!lastname.length) {
      validation.lastname = t("contacts.errors.empty");
    }
    if (!REGEXP_MAIL.test(mail) && mail) {
      validation.mail = t("contacts.errors.mail");
    }
    if (!mail.length) {
      validation.mail = t("contacts.errors.empty");
    }
    if (!REGEXP_WORDS.test(name) && name) {
      validation.name = t("contacts.errors.words");
    }
    if (!name.length) {
      validation.name = t("contacts.errors.empty");
    }
    if (!phone.length) {
      validation.phone = t("contacts.errors.empty");
    }

    setErrors(validation);

    const hasErrors = Object.values(validation).some((v) => v);
    if (!hasErrors) {
      setName("");
      setLastname("");
      setPhone("");
      setMail("");
      setComment("");
      enqueueSnackbar("", {
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        autoHideDuration: 3000,
        className: "form__notification",
        variant: "success",
      });
    }
  }, [comment, enqueueSnackbar, lastname, mail, name, phone, t]);

  const getButtonProps = React.useCallback(
    () =>
      ({
        className: "form__send",
        onClick: onButtonClick,
      } as ButtonProps),
    [onButtonClick]
  );

  const values = React.useMemo(
    () => ({
      errors,
    }),
    [errors]
  );

  return {
    getButtonProps,
    getFieldCommentProps,
    getFieldLastnameProps,
    getFieldMailProps,
    getFieldNameProps,
    getPhoneProps,
    values,
  };
};
