import React from "react";
import MuiModal from "@mui/material/Modal";
import MuiSlide from "@mui/material/Slide";

import { useMain } from "./main-hooks";

import "./main.scss";

export const MainComponent = React.memo(() => {
  const { getMainProps, getModalProps, getPlayerButtonProps, getSlideProps } =
    useMain();
  return (
    <div {...getMainProps()}>
      <button {...getPlayerButtonProps()} />
      <MuiModal {...getModalProps()}>
        <MuiSlide direction="up" {...getSlideProps()}>
          <div id="video" className="main__video" />
        </MuiSlide>
      </MuiModal>
    </div>
  );
});

MainComponent.displayName = "MainComponent";
