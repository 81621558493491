import React from "react";

import { NavLink } from "react-router-dom";

import { LinkOwnProps } from "./link-types";
import { useLink } from "./link-hooks";

export const LinkComponent = (props: LinkOwnProps) => {
  const { getLinkProps, values } = useLink(props);
  return <NavLink {...getLinkProps()}>{values.translation}</NavLink>;
};
