import React from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";

import { runPlayer } from "./main-utils";

export const useMain = () => {
  const [open, setOpen] = React.useState(false);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (open) {
      runPlayer();
    }
  }, [open]);

  const getModalProps = React.useCallback(
    () => ({
      onClose: () => setOpen(false),
      open,
    }),
    [open]
  );

  const getPlayerButtonProps = React.useCallback(
    () => ({
      className: "main__play",
      onClick: () => setOpen(true),
    }),
    []
  );

  const getMainProps = React.useCallback(
    () => ({
      className: cn("main", {
        "main--am": i18n.language === "AM",
        "main--us": i18n.language === "GB",
      }),
    }),
    [i18n.language]
  );

  const getSlideProps = React.useCallback(
    () => ({
      in: open,
    }),
    [open]
  );

  return { getMainProps, getModalProps, getPlayerButtonProps, getSlideProps };
};
