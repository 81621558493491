import React from "react";

import { useTranslation } from "react-i18next";

import { TextOwnProps } from "./text-types";

export const useText = (props: TextOwnProps) => {
  const { i18n, t } = useTranslation();

  const getTextProps = React.useCallback(
    () => ({
      className: props.className,
      onClick: props.onClick,
      style: {
        fontFamily: i18n.language === "AM" && "Arm Hmk\\'s Bebas Neue",
        fontWeight: i18n.language === "AM" && 100,
        ...(props.style && props.style),
      } as React.CSSProperties,
    }),
    [i18n.language, props.className, props.onClick, props.style]
  );

  const values = React.useMemo(
    () => ({
      translation: props.value || t(props.path || ""),
    }),
    [t, props]
  );

  return { getTextProps, values };
};
