import React from "react";

import { API } from "src/services";
import { Game, Order, Status } from "src/types";
import { GamesOwnProps } from "./games-types";
import { getGames } from "./games-utils";

export const useGames = (props: GamesOwnProps) => {
  const [games, setGames] = React.useState<Array<Game & Order & Status>>([]);

  React.useEffect(() => {
    API.games(props.id).then((response) => {
      setGames(getGames(response.data.result || []));
    });
  }, [props.id]);

  const values = React.useMemo(
    () => ({
      games,
    }),
    [games]
  );

  return { values };
};
