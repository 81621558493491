import React from "react";
import DatePicker from "react-datepicker";

import { formatDateAr } from "./table-utils";
import { Games } from "./games";
import { getRacers } from "src/utils";
import { Racer, Text } from "src/components/shared";
import { useTable } from "./table-hooks";

import "react-datepicker/dist/react-datepicker.css";

import "./table.scss";

export const TableComponent = React.memo(() => {
  const {
    getCalendarProps,
    getCalendarButtonProps,
    getDateProps,
    getNextProps,
    getPrevProps,
    getTitleProps,
    getTournamentProps,
    values,
  } = useTable();
  return (
    <div className="table">
      <div className="table__buttons">
        <button {...getPrevProps()} />
        <div className="table__tournaments">
          {values.tournaments.map((tournament) => (
            <button key={tournament.id} {...getTournamentProps(tournament)}>
              <Text
                {...getDateProps(
                  `${values.texts.start} ${formatDateAr(
                    new Date(tournament.created_at)
                  )}`
                )}
              />
              <Text
                {...getTitleProps(`${values.texts.tournament} №${tournament.id}`)}
              />
            </button>
          ))}
        </div>
        <button {...getNextProps()} />
        <button {...getCalendarButtonProps()} />
      </div>
      {values.mode === "tournament" && values.tournament && (
        <div className="table__info">
          <div className="table__racers">
            {getRacers(values.racers).map((racer, index) => (
              <Racer key={racer.id} position={++index} racer={racer} />
            ))}
          </div>
          <Games id={values.tournament.id} racers={getRacers(values.racers)} />
        </div>
      )}
      {values.mode === "calendar" && (
        <div className="table__calendar">
          <DatePicker {...getCalendarProps(1)} />
          <DatePicker {...getCalendarProps(0)} />
        </div>
      )}
    </div>
  );
});

TableComponent.displayName = "TableComponent";
