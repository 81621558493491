import React from "react";

import { Form } from "./form";
import { Text } from "src/components/shared";

import "./contacts.scss";

export const ContactsComponent = React.memo(() => {
  return (
    <div className="contacts">
      <div>
        <div>
          <Text className="contacts__title" path="contacts.business.title" />
          <Text className="contacts__description" path="contacts.business.value" />
        </div>
        <div>
          <Text className="contacts__title" path="contacts.adress.title" />
          <Text className="contacts__description" path="contacts.adress.value" />
        </div>
        <div>
          <Text className="contacts__title" path="contacts.director.title" />
          <Text className="contacts__description" path="contacts.director.value" />
        </div>
        <div>
          <Text className="contacts__title" path="contacts.inn" />
          <span className="contacts__description">00517064</span>
        </div>
        <div>
          <Text className="contacts__title" path="contacts.registration" />
          <span className="contacts__description">211.171.1308637</span>
        </div>
      </div>
      <div>
        <Form />
      </div>
    </div>
  );
});

ContactsComponent.displayName = "ContactsComponent";
