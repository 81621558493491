import React from "react";
import cn from "classnames";

import { useLocation } from "react-router-dom";
import { useTransition } from "react-spring";
import { useTranslation } from "react-i18next";

export const useApp = () => {
  const location = useLocation();
  const locationTransition = useTransition(location.pathname, {
    from: { opacity: 0, transform: "translate3d(100vw, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
  });

  const { i18n } = useTranslation();

  React.useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Главная";
        break;
      case "/racer":
        document.title = "Гонщики";
        break;
      case "/table":
        document.title = "Расписание";
        break;
      case "/rating":
        document.title = "Рейтинг";
        break;
      case "/regulation":
        document.title = "Регламенты";
        break;
      case "/contact":
        document.title = "Контакты";
        break;
      default:
        document.title = "";
        break;
    }
  }, [location]);

  const getAppProps = React.useCallback(
    () => ({
      className: cn("app", { "app--arm": i18n.language === "AM" }),
    }),
    [i18n]
  );

  const values = React.useMemo(
    () => ({
      location: locationTransition,
    }),
    [locationTransition]
  );

  return { getAppProps, values };
};
