import React from "react";

import { Game } from "./game";
import { GamesOwnProps } from "./games-types";
import { useGames } from "./games-hooks";

import "./games.scss";

export const GamesComponent = (props: GamesOwnProps) => {
  const { values } = useGames(props);
  return (
    <div className="games__games">
      {values.games.map((game) => (
        <Game key={game.id} game={game} racers={props.racers} />
      ))}
    </div>
  );
};
