import React from "react";
import MuiTextField from "@mui/material/TextField";

import { Text } from "src/components/shared";
import { useForm } from "./form-hooks";

import "./form.scss";

export const FormComponent = React.memo(() => {
  const {
    getButtonProps,
    getFieldCommentProps,
    getFieldLastnameProps,
    getFieldMailProps,
    getFieldNameProps,
    getPhoneProps,
    values,
  } = useForm();
  return (
    <div className="form">
      <div className="form__header">
        <Text className="form__title" path="contacts.header.primary" />
        <Text className="form__title--secondary" path="contacts.header.secondary" />
      </div>
      <div className="form__body">
        <div>
          <MuiTextField
            {...getFieldNameProps()}
            label={<Text path="contacts.name" />}
            helperText={<Text value={values.errors.name} />}
          />
          <MuiTextField
            {...getFieldLastnameProps()}
            label={<Text path="contacts.surname" />}
            helperText={<Text value={values.errors.lastname} />}
          />
        </div>
        <div>
          <MuiTextField
            {...getPhoneProps()}
            label={<Text path="contacts.tel" />}
            helperText={<Text value={values.errors.phone} />}
          />
          <MuiTextField
            {...getFieldMailProps()}
            label={<Text path="contacts.mail" />}
            helperText={<Text value={values.errors.mail} />}
          />
        </div>
        <div>
          <MuiTextField
            {...getFieldCommentProps()}
            label={<Text path="contacts.comment" />}
            helperText={<Text value={values.errors.comment} />}
          />
        </div>
        <div>
          <button {...getButtonProps()}>
            <Text className="form__send-label" path="contacts.send" />
          </button>
        </div>
      </div>
    </div>
  );
});

FormComponent.displayName = "FormComponent";
