import React from "react";

import { useTranslation } from "react-i18next";

import { API } from "src/services";
import { getNickname, getRacers } from "src/utils";
import { Racer } from "src/types";

export const useRacers = () => {
  const [racers, setRacers] = React.useState<Array<Racer>>([]);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    API.racers().then((response) => {
      if (response.data && response.data.result) {
        setRacers(getRacers(response.data.result));
      }
    });
  }, []);

  const getDataProps = React.useCallback(
    (place: number) => ({
      style: {
        background:
          place === 1
            ? "#016268"
            : place === 2
            ? "#02474B"
            : place === 3
            ? "#002E31"
            : "#002527",
      } as React.CSSProperties,
    }),
    []
  );

  const getNameProps = React.useCallback(
    (value: Racer) => ({
      className: "rating__info",
      style: {
        textAlign: "left",
        width: "100%",
      } as React.CSSProperties,
      value: getNickname(value, i18n.language),
    }),
    [i18n.language]
  );

  const values = React.useMemo(
    () => ({
      language: i18n.language,
      racers: racers.map((racer, i) => ({ ...racer, place: ++i })),
    }),
    [i18n.language, racers]
  );

  return { getDataProps, getNameProps, values };
};
