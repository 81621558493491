import React from "react";
import cn from "classnames";

import { useTransition } from "react-spring";
import { useTranslation } from "react-i18next";

import { API } from "src/services";
import { getNickname, getRacers } from "src/utils";
import { Racer } from "src/types";

export const useRacers = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [racers, setRacers] = React.useState<Array<Racer>>([]);
  const [racer, setRacer] = React.useState<Racer | null>(null);

  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    API.racers().then((response) => {
      if (response.data && response.data.result) {
        const racers = getRacers(response.data.result);
        const active = racers.filter((racer) => racer.active);
        setRacers(active);
        setLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    if (racers.length) {
      setRacer(racers[0]);
    }
  }, [racers]);

  const getItemProps = React.useCallback(
    (value: Racer) => ({
      className: cn("racers__list-item", {
        "racers__list-item--selected": value.id === racer?.id,
      }),
      onClick: () => setRacer(value),
      value: getNickname(value, i18n.language),
    }),
    [i18n.language, racer]
  );

  const name = React.useMemo(() => {
    if (racer) {
      const nickname = getNickname(racer, i18n.language);
      return nickname.split(" ")[0] || "";
    } else {
      return "";
    }
  }, [i18n.language, racer]);
  const surname = React.useMemo(() => {
    if (racer) {
      const nickname = getNickname(racer, i18n.language);
      return nickname.split(" ")[1] || "";
    } else {
      return "";
    }
  }, [i18n.language, racer]);

  const getNameProps = React.useCallback(
    () => ({
      className: "racers__name",
      value: name,
    }),
    [name]
  );

  const getSurnameProps = React.useCallback(
    () => ({
      className: cn("racers__surname", {
        "racers__surname--long": surname.length > 6,
      }),
      value: surname,
    }),
    [surname]
  );

  const racerTransition = useTransition(racer, {
    from: {
      opacity: 0,
      transform: "translate3d(100vw, 0, 0)",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  });

  const texts = React.useMemo(
    () => ({
      age: t("racers.age"),
      rating: t("racers.rating"),
      years: t("racers.years"),
    }),
    [t]
  );

  const values = React.useMemo(
    () => ({
      loading,
      name,
      racer: racerTransition,
      racers,
      surname,
      texts,
    }),
    [loading, name, racerTransition, racers, surname, texts]
  );

  return { getItemProps, getNameProps, getSurnameProps, values };
};
