import React from "react";
import { animated } from "react-spring";
import { Route, Routes } from "react-router-dom";

import { Contacts, Main, Racers, Rating, Regulations, Table } from "./routes";
import { NavBar } from "./nav-bar";
import { useApp } from "./app-hooks";

import "./app.scss";

export const AppComponent = () => {
  const { getAppProps, values } = useApp();
  return (
    <div {...getAppProps()}>
      <NavBar />
      {values.location((props, item) => (
        <animated.div className="app__routes" style={props}>
          <Routes location={item}>
            <Route path="/" element={<Main />} />
            <Route path="/racer" element={<Racers />} />
            <Route path="/table" element={<Table />} />
            <Route path="/rating" element={<Rating />} />
            <Route path="/regulation" element={<Regulations />} />
            <Route path="/contact" element={<Contacts />} />
          </Routes>
        </animated.div>
      ))}
    </div>
  );
};

AppComponent.displayName = "AppComponent";
