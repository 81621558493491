import EgPlayer from "@atm/eg-player";

export const runPlayer = () => {
  const player = new EgPlayer({
    el: "video",
    exchangeUrl: ["https://cdn.cloud.atmosfera.cam/player/v2/config"],
    auth: {
      provider: "cupis",
      platform: "cupis",
      merchant: "raceliga.am",
      projectId: "cupis",
      account: "carracing",
    },
    uiTheme: "custom",
    uiStylesGlobal: `
      .ui-custom > .loader {
        display: block;
      }
      video {
        object-fit: cover;
      }`,
  });

  player.on("STARTED", () => {
    // eslint-disable-next-line
    console.log("PLAYER STARTED");
    player.uiStylesSet("loader", "display: none;");
  });

  player.on("READY", () => {
    // eslint-disable-next-line
    console.log("PLAYER READY");
    player.start({ game: "carracing", view: "main", quality: "360p" });
  });

  player.init();
};
